@charset "utf-8";

// @import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Montserrat|Roboto:100,300');

$family-sans-serif: 'Roboto', sans-serif;
// $family-sans-serif: 'Noto Sans', sans-serif;

$text: rgba(84, 93, 103, 1);
$link: rgba(84, 93, 103, 1);

// $body-background-color: $beige-lighter;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

@import "../node_modules/bulma/bulma.sass";
// Import only what you need from Bulma
// @import '../node_modules/bulma/sass/utilities/_all.sass';
// @import '../node_modules/bulma/sass/base/_all.sass';
// @import '../node_modules/bulma/sass/elements/button.sass';
// @import '../node_modules/bulma/sass/elements/container.sass';
// @import '../node_modules/bulma/sass/elements/form.sass';
// @import '../node_modules/bulma/sass/elements/title.sass';
// @import '../node_modules/bulma/sass/components/navbar.sass';
// @import '../node_modules/bulma/sass/layout/hero.sass';
// @import '../node_modules/bulma/sass/layout/section.sass';

.logos img { margin: 1em 1.8em 1em 0; };
.content ul { margin-left: 0; };
.social-icons a { margin-right: 1em; };
li { list-style: none; };
// h1, h6 { font-family: 'Montserrat', sans-serif; font-weight: 100; }
